<template>
  <div>
    <TeacherAttendanceTableControl class="mb-5" />

    <AppPageCard>
      <template #card-title> {{ $t("EDUATTENDANCE.TEACHER.MES1") }} </template>
      <template #card-toolbar> <TeacherAttendancePageControl /> </template>
      <b-nav pills>
        <b-nav-item to="daily" exact exact-active-class="active">{{
          $t("EDUATTENDANCE.GENERAL.DAILY")
        }}</b-nav-item>
        <b-nav-item to="monthly" exact exact-active-class="active">{{
          $t("EDUATTENDANCE.GENERAL.MONTHLY")
        }}</b-nav-item>
        <b-nav-item to="yearly" exact exact-active-class="active">{{
          $t("EDUATTENDANCE.GENERAL.YEARLY")
        }}</b-nav-item>
      </b-nav>

      <div class="mt-5">
        <router-view> </router-view>
      </div>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import TeacherAttendanceTableControl from "@/modules/school/components/management/attendance/teacher/control/TeacherAttendanceTableControl";
import TeacherAttendancePageControl from "@/modules/school/components/management/attendance/teacher/TeacherAttendancePageControl";

export default {
  name: "SchoolTeacherAttendancePage",
  components: {
    TeacherAttendancePageControl,
    TeacherAttendanceTableControl,
    AppPageCard,
  },
};
</script>

<style scoped></style>
