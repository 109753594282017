<template>
  <TeacherDailyAttendanceTableControl v-if="type === 1" />
  <TeacherMonthlyAttendanceTableControl v-else-if="type === 2" />
  <TeacherYearlyAttendanceTableControl v-else-if="type === 3" />
</template>

<script>
import TeacherDailyAttendanceTableControl from "@/modules/school/components/management/attendance/teacher/control/TeacherDailyAttendanceTableControl";
import TeacherMonthlyAttendanceTableControl from "@/modules/school/components/management/attendance/teacher/control/TeacherMonthlyAttendanceTableControl";
import TeacherYearlyAttendanceTableControl from "@/modules/school/components/management/attendance/teacher/control/TeacherYearlyAttendanceTableControl";

export default {
  name: "TeacherAttendanceTableControl",
  components: {
    TeacherYearlyAttendanceTableControl,
    TeacherMonthlyAttendanceTableControl,
    TeacherDailyAttendanceTableControl,
  },
  data() {
    return {
      type: null,
    };
  },

  mounted() {
    this.checkRoute(this.$route.name);
  },

  methods: {
    checkRoute(name) {
      switch (name) {
        case "attendanceTeacher":
          this.type = 1;
          break;
        case "attendanceTeacherMonthly":
          this.type = 2;
          break;
        case "attendanceTeacherYearly":
          this.type = 3;
          break;
        default:
          this.type = 4;
          break;
      }
    },
  },

  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
