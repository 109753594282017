<template>
  <AppTableControl
    @search-clicked="beginSearch"
    @query-debounced="searchByName"
    @export-excel-clicked="downloadYearlyReport(1)"
    @export-pdf-clicked="downloadYearlyReport(2)"
  >
    <template #filters>
      <AppFieldYearSelector
        :date-value="engageDate"
        no-search
        @clickedSearch="filterDate"
        @fetched-date="filterDate"
      ></AppFieldYearSelector>
    </template>
  </AppTableControl>
</template>

<script>
import dayjs from "dayjs";
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
import AppFieldYearSelector from "@/modules/core/components/utility/date/AppFieldYearSelector";

export default {
  name: "TeacherYearlyAttendanceTableControl",
  components: { AppFieldYearSelector, AppTableControl },
  data() {
    return {
      engageDate: false,
    };
  },
  beforeDestroy() {
    console.log("Resetting filters");

    // Year date filter
    this.$store.dispatch("changeTeacherYearlyAttendanceListDate", {
      date: dayjs().format(),
    });

    // Search base name filter
    this.$store.dispatch("changeTeacherYearlyAttendanceNameFilter", {
      name: null,
    });
  },

  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherYearlyAttendanceList")
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    filterDate(data) {
      console.log("Date ", data);
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: data.year,
      });

      this.year = data.year;

      this.date = date.format();

      this.$store.dispatch("changeTeacherYearlyAttendanceListDate", {
        date: date.format(),
      });

      this.fetch();
    },

    /**
     * Search by name query.
     *
     * @param nameQuery
     */
    searchByName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeTeacherYearlyAttendanceNameFilter", {
        name: nameQuery,
      });

      this.fetch();
    },

    beginSearch() {
      console.log("Begin search");

      this.engageDate = !this.engageDate;
    },

    /**
     * Download report by type
     *
     * @param type
     */
    async downloadYearlyReport(type) {
      console.log("Selected type is : ", type);

      try {
        this.$bvToast.toast("Downloading Report . . .", {
          variant: "primary",
        });

        const response = await this.$store.dispatch(
          "downloadTeacherYearlyAttendanceReport",
          { type }
        );

        console.log(response);
        console.log("Successfully downloaded report of type : ", type);

        this.$bvToast.toast("Successfully downloaded report . . .", {
          variant: "primary",
        });
      } catch (e) {
        console.error(e);

        await this.$bvModal.msgBoxOk("Error", {
          okVariant: "danger",
        });
      }
    },
  },
};
</script>

<style scoped></style>
