<template>
  <EmployeeAttendancePageControl
    @downloadClicked="download"
    @refreshClicked="refresh"
  ></EmployeeAttendancePageControl>
</template>

<script>
import EmployeeAttendancePageControl from "@/modules/school/components/shared/management/attendance/controls/EmployeeAttendancePageControl";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "TeacherAttendancePageControl",
  components: { EmployeeAttendancePageControl },
  methods: {
    /**
     *
     */
    download(data) {
      const type = this.getRouteType();

      switch (type) {
        case "daily": {
          this.$store.dispatch("downloadTeacherDailyAttendanceReport", {
            date: dayjs().format(),
            type: data.format,
          });
          break;
        }
        case "monthly": {
          this.$store.dispatch("downloadTeacherMonthlyAttendanceReport", {
            date: this.monthlyDate ?? dayjs().format(),
            type: data.format,
          });
          break;
        }
        default:
          break;
      }
    },

    /**
     *
     */
    refresh() {
      const type = this.getRouteType();

      switch (type) {
        case "daily": {
          this.$store.dispatch("fetchTeacherDailyAttendanceList");
          break;
        }
        case "monthly": {
          this.$store.dispatch("fetchTeacherMonthlyAttendanceList");
          break;
        }
        case "yearly":
          this.$store.dispatch("fetchTeacherYearlyAttendanceList");
          break;
        default:
          break;
      }
    },

    /**
     * Get route child type.
     *
     * @returns {string}
     */
    getRouteType() {
      const route = this.$route.name;
      console.log("Current route :", route);

      let type = null;
      if (route === "attendanceTeacher") {
        console.log("Route is teacher daily attendance.");
        type = "daily";
      } else if (route === "attendanceTeacherMonthly") {
        console.log("Route is teacher monthly attendance.");
        type = "monthly";
      } else {
        type = "yearly";
      }

      return type;
    },
  },
  computed: {
    ...mapGetters({
      monthlyDate: "getTeacherMonthlyAttendanceListDate",
    }),
  },
};
</script>

<style scoped></style>
